<template>
  <h1>Coming Soon..</h1>
</template>

<script>
export default {
  name: 'ComingSoon',
  components: {

  },
  data() {
    return {

    }
  },
  mounted() {
  window.location.assign('https://biggerfishes.com/')
}
  // methods: {
  // goToWebsite() {
  //   window.location.assign('https://biggerfish.godaddysites.com')
  // }
// }
//   methods: {
//   goToWebsite() {
//     window.open('https://biggerfish.godaddysites.com', '_blank')
//   }
// }
}
</script>
